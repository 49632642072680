import fetch from "@/api/fetch";

function getNumber() {
	return fetch({
		url: `/client/im/user/unit/get`,
		method: "GET"
	})
}



export function Switch(info) {
	return new Promise((resolver, reject) => {
		
		getNumber().then(res => {
			resolver({
				"sunit": {
					"Q": res.heatLoad,
					"T": res.temperature,
					"P": res.pressure,
					"DPr": res.refPressureDrop,
					"DPa": res.airPressureDrop,
					"V": res.velocity,
					"VFlow": res.volumeFlow,
					"MFlow": res.massFlow,
					"Chag": res.charge
				},
				"iPatt":info.form.HXPatt,
				"sRefName": info.form.refrigerant,
				"dRefInP": info.form.exhaustPressure,
				"dRefInT": info.form.exhaustTemperature,
				"dRefOutSC": info.form.supercooling,
				"dRefVF":info.form.refvolflow,
				"dAirInT": info.form.ambientTemperature,
				"dAirInV": info.form.windSpeed,
				"iFanNum":info.windType?info.form.fanNum:0,
				"iFanUser":info.form.windType,
				"lFanCurve": [{
					"vf": info.form.fanCurve.v1,
					"dp": info.form.fanCurve.p1,
				}, {
					"vf": info.form.fanCurve.v2,
					"dp": info.form.fanCurve.p2,
				}, {
					"vf": info.form.fanCurve.v3,
					"dp": info.form.fanCurve.p3,
				}, {
					"vf": info.form.fanCurve.v4,
					"dp": info.form.fanCurve.p4,
				}, {
					"vf": info.form.fanCurve.v5,
					"dp": info.form.fanCurve.p5,
				}, {
					"vf": info.form.fanCurve.v6,
					"dp": info.form.fanCurve.p6,
				}, {
					"vf": info.form.fanCurve.v7,
					"dp": info.form.fanCurve.p7,
				}, {
					"vf": info.form.fanCurve.v8,
					"dp": info.form.fanCurve.p8,
				}],
				"dQmin": info.exhaustHeatLoad,
				"dLmin": 300,
				"dLmax": 600,
				"dHmin": 200,
				"dHmax": 500
			})
		})
	})

}

export function Check(info) {
	console.log(info,"info")
	return new Promise((resolver, reject) => {
		getNumber().then(res => {
			resolver({
				"sunit": {
					"Q": res.heatLoad,
					"T": res.temperature,
					"P": res.pressure,
					"DPr": res.refPressureDrop,
					"DPa": res.airPressureDrop,
					"V": res.velocity,
					"VFlow": res.volumeFlow,
					"MFlow": res.massFlow,
					"Chag": res.charge
				},
				"sProduct": info.model,
				"iPatt":info.form.HXPatt,
				"sRefName": info.form.refrigerant,
				"dRefInP": info.form.exhaustPressure,
				"dRefInT": info.form.exhaustTemperature,
				"dRefOutSC": info.form.supercooling,
				"dRefVF":info.form.refvolflow,
				"dAirInT": info.form.ambientTemperature,
				"dAirInV": info.form.windSpeed,
				"iFanNum":  info.windType?info.form.fanNum:0,
				"iFanUser":info.form.windType,
				"lFanCurve": [{
					"vf": info.form.fanCurve.v1,
					"dp": info.form.fanCurve.p1,
				}, {
					"vf": info.form.fanCurve.v2,
					"dp": info.form.fanCurve.p2,
				}, {
					"vf": info.form.fanCurve.v3,
					"dp": info.form.fanCurve.p3,
				}, {
					"vf": info.form.fanCurve.v4,
					"dp": info.form.fanCurve.p4,
				}, {
					"vf": info.form.fanCurve.v5,
					"dp": info.form.fanCurve.p5,
				}, {
					"vf": info.form.fanCurve.v6,
					"dp": info.form.fanCurve.p6,
				}, {
					"vf": info.form.fanCurve.v7,
					"dp": info.form.fanCurve.p7,
				}, {
					"vf": info.form.fanCurve.v8,
					"dp": info.form.fanCurve.p8,
				}],
				// "dQmin": info.exhaustHeatLoad,
			})
		})
	})

}


