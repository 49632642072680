<template>
	<div class="wrap">
		<div class="header" >
       
    
			
			<!-- 品牌 -->
			<div class="category" style="display: flex;justify-content: space-between;">
				<div style="display: flex;">
					<div class="line" v-for="(item,index) in category" @click="clkcategory(item,index,'category')"
						:class="current1==index?'changes':''">
						<div class="title hover">{{ $lang(item,'name') }}</div>
					</div>
				</div>
				
				
				<div style="color: #006DCC;cursor: pointer;" @click="clear">{{$t("newadd.t.清空")}}</div>
			</div>


			<!-- 产品 -->
			<div class="category" style="display: flex;" v-if="product.length">
				
				<div class="line" v-for="(item,index) in product" @click="clkcategory(item,index,'product')"
					:class="current2==index?'changes':''">
					<div class="title">{{ $lang(item,'name') }}</div>
				</div>
			</div>


			<!-- 规格 -->
			<div class="category" style="display: flex;" v-if="size.length">
				
				<div class="line" v-for="(item,index) in size" @click="clkcategory(item,index,'size')"
					:class="current3==index?'changes':''">
					<div class="title">{{ $lang(item,'name') }}</div>
				</div>
			</div>

			<div class="search" style="border-left: 1px solid #ccc;border-right: 1px solid #ccc;padding: 0 10px;">

				<!-- 搜索 -->
				<div >
					<el-input :placeholder="$t('mall.mesPleaseEnterModel')" size="mini" v-model="form.keyword">
						<el-button slot="append" icon="el-icon-search" @click="keyword">{{ $t('operation.search') }}
						</el-button>

					</el-input>
				</div>

				<!-- 价格 -->
				<div style="margin:0px 10px 0px 20px">
					<el-dropdown @command="command">
						<span class="el-dropdown-link">
							{{ $t('newadd.t.价格排序') }}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="price,desc">{{ $t("mall.mesHighToLow") }}</el-dropdown-item>
							<el-dropdown-item command="price,asc">{{ $t("mall.mesLowToHigh") }}</el-dropdown-item>
							<el-dropdown-item command="sales,desc">{{ $t("mall.salesToLow") }}</el-dropdown-item>
							<el-dropdown-item command="sales,asc">{{ $t("mall.salesToHigh") }}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<div>
					<el-input :placeholder="$t('newadd.t.最低价格')" size="mini" style="width: 80px;" v-model="form.starePrice"
						@change="change"></el-input>
				</div>
				<div style="margin:0 5px">-</div>
				<div>
					<el-input :placeholder="$t('newadd.t.最高价格')" size="mini" style="width: 80px;" v-model="form.endPrice" @change="change">
					</el-input>
				</div>
				
				
				<!-- 尺寸筛选 -->
				
				<template  v-if="type==3">
				
                <div style="margin:0px 10px 0px 20px">
                    {{ $t('newadd.t.尺寸筛选') }}
                </div>
				
				<div style="margin:0px 10px 0px 10px">
				    {{ $t('newadd.t.长度') }}
				</div>
				
				<div>
					<el-input placeholder="mm" size="mini" style="width: 80px;" v-model="form.minLen" @change="change"></el-input>
				</div>
				<div style="margin:0 5px">-</div>
				<div>
					<el-input placeholder="mm" size="mini" style="width: 80px;" v-model="form.maxLen" @change="change">
					</el-input>
				</div>
				
				<div style="margin:0px 10px 0px 10px">
				    {{ $t('newadd.t.宽度') }}
				</div>
				
				<div>
					<el-input placeholder="mm" size="mini" style="width: 80px;" v-model="form.minWidth" @change="change"></el-input>
				</div>
				<div style="margin:0 5px">-</div>
				<div>
					<el-input placeholder="mm" size="mini" style="width: 80px;" v-model="form.maxWidth" @change="change">
					</el-input>
				</div>
				
			    </template>

			</div>
			
			<div  v-if="type==2" class="category" style="border-bottom: 1px solid #e1e1e1;cursor: pointer;" @click="selectModel" >
				{{$t("newadd.t.根据工况选型")}}<i class="el-icon-arrow-right "></i>
			</div>
			
			
			<el-dialog  :visible.sync="dialogTableVisible">
			    <selectStatus @getInfo="getInfo" :isHot="true"></selectStatus>
			</el-dialog>

			
			

			<div class="goods">

				<div class="goods-list" v-for="(item,index) in goodsList" @click="navTo(item)">
					<div class="goods-list-img">

						<img :src="$req.getFileUrl(item.img)" border="0"
							width="220px" height="220px"></img>
					</div>
					<div style="padding:10px;">

						<div class="goods-list-title">{{$lang(item, "title")}} </div>
						<div class="goods-list-price">
					     	<span v-if="type==1">￥{{item.priceMin}} - ￥{{item.priceMin}}</span>	
							<span  v-if="type==2">￥{{item.uniMoneySample}}</span>
						</div>
						<div class="goods-list-num">
							<span>{{ $t("mall.sold") }}{{ item.sales }}{{ $t("mall.piece") }}</span>
							<span>{{ $t("mall.MinimumOrderQuantity") }}>{{ item.purNum?'':'1' }}{{ $t("mall.piece") }}</span>
						</div>
					</div>
				</div>
			</div>


			<Pagination :Pinfo="Pinfo" @pageination="get" />

		</div>
	</div>
</template>

<script>
	import {
		goodsList,
		getclassify
	} from "@/api/goods";
	import {
		mapState,
		mapMutations
	} from "vuex";
	import Pagination from '@/components/pagination';
	import selectStatus from "@/views/Custom/pages/page0";
	
	import { Switch } from "@/utils/ConditionSwitch";
	
	
	export default {
		components: {
			Pagination,
			selectStatus
		},
		computed: {
			...mapState(["fileServer"]),
		},

		data() {
			return {
				dialogTableVisible:false,//选型弹框
				
				Pinfo:{},
	
				pageSize:20,
				currentPage:1,
				
				current1: -1,
				activeName: "first",
				form: {
					starePrice: "",
					endPrice: "",
					minLen: "",
					maxLen: "",
					keyword: "",
					minWidth: "",
					maxWidth: "",
					paixu: "asc",
					paixustatue: "price",
                    workingCondition:"",

				},
				category: [],
				product: [],
				goodsList: [],
				current2: -1,

				current3: -1,

				size: [],
				classifyId: "",
				type:''
			};
		},
		watch: {
			current1: {
				handler(v) {
					getclassify(this.category[this.current1].id).then(res => {
						this.product = res;
						this.current3 = -1;
						this.size = [];
						this.current2 = -1;
					}).catch(err => {

					})

				}
			},
			current2: {
				handler(v) {
					getclassify(this.product[this.current2].id).then(res => {
						this.size = res;
					
						this.current3 = -1;
					}).catch(err => {

					})

				}
			}
		},
		created() {
		  
			this.getclassify()

			this.jiazai()
		},
		methods: {
			
			selectModel(){
				const {id,detailType,type} = this.$route.query;
				if(detailType=="501" || detailType=="401" ){
					this.$notify.info({
									title: this.$t('inviteCode.mesAlertTitle'),
									message:   this.$t('newadd.t.功能开发中'),
									duration: 2000
					});
					return
				}
				
			    
				this.dialogTableVisible=true;
			},
			clear(){
				this.current3 = -1;
				this.current2 = -1;
				this.current1 = -1;
				this.classifyId='';
				this.product=[];
				this.size=[];
				this.jiazai()
			},
			// 工况选行
			getInfo(e){
				this.dialogTableVisible=false;
				Switch(e).then(res=>{
					this.form.workingCondition=JSON.stringify(res);
					this.jiazai()
				})	
			},
			getclassify() {
				const {id,detailType,type} = this.$route.query;
				getclassify(type==1?'1':detailType=='201'?'10':'11').then(res => {
					this.category = res
				}).catch(err => {

				})
			},

			get(e) {
				this.pageSize = e.pageSize;
				this.currentPage = e.currentPage;
				this.jiazai()

			},
			clkcategory(item, index, e) {
				if (e == "size") {
					this.current3 = index;
					this.classifyId = item.id
				}
				if (e == "category") {
					this.current1 = index;
					this.classifyId = item.id
				}

				if (e == "product") {
					this.current2 = index;
					this.classifyId = item.id
				}

				this.jiazai()



			},

			keyword() {

				this.jiazai()
			},
			change() {
				this.jiazai()
			},
			command(e) {
				var paixustatue = e.split(",")[0];
				var paixu = e.split(",")[1]

				this.form.paixustatue = paixustatue;
				this.form.paixu = paixu;
				this.jiazai()
			},
			jiazai() {
				const {id,detailType,type} = this.$route.query;
				this.type=type;
				goodsList(id, this.form.paixustatue, this.form.paixu, this.form.starePrice, this.form.endPrice, this
						.form.keyword, this.currentPage, this.pageSize,this.classifyId,type,detailType,this.form.workingCondition)
					.then((res) => {
						this.goodsList = res.records;
						this.Pinfo=res;
						this.loading=false

					})
					.catch((err) => {});
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
			navTo(item) {
                 const {detailType} = this.$route.query;
				this.$router.push({
					path:this.type==1? "/goodsDetail":this.type==2?'/goodsNormalDetail':'',
					query: {
						id: item.id,
						detailType:detailType
					}
				});

			},
		},
	};
</script>

<style  >
	.wrap {
		width: 1200px;
		margin: 60px auto;
		color: #555;
		font-size: 14px;
	}



	.category-title {
		margin: 0 0 10px 0;
	}

	.category-list li {
		display: inline;
		margin-right: 10px;
	}

	.category {
		border-top: 1px solid #ccc;
		border-left: 1px solid #ccc;
		border-right: 1px solid #ccc;
		padding: 10px;
	}

	.line {
		display: flex;
		flex-direction: row;

	}

	.title {
		margin-right: 40px;
	}

	.search {
		display: flex;
		flex-direction: row;
		background-color: #eee;
		line-height: 50px;
		flex-wrap: wrap;
	}

	.goods {
		padding: 10px 0px;
		font-size: 12px;
		color: #555;
		display: flex;
		flex-flow: row wrap;
	}

	.goods-list {
		margin-top: 20px;

		width: 220px;
		margin-right: 20px;
		border: 1px solid #ccc;


	}

	.goods-list img {
		vertical-align: middle;
		box-sizing: border-box;
	}

	.goods-list-img {
		border: none;

	}

	.goods-list-title {
		line-height: 20px;
		min-height: 40px;
		overflow: hidden;
	}

	.goods-list-price {
		font-size: 20px;
		color: #f25712;
		margin-bottom: 5px;
	}

	.goods-list-num {
		display: flex;
		justify-content: space-between;
	}

	.el-dropdown-link {
		cursor: pointer;
		color: #555;
	}

	.el-icon-arrow-down {
		font-size: 12px;
	}

	.changes {
		color: #3C66D7;
		font-weight: bold;
	}
</style>
